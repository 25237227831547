<template>
	<div data-route>
		<div data-element="container">
			<p
				v-if="!getIsOpen"
				data-element="error-text"
			>
				{{ getErrorMessage }}
			</p>
			<template v-if="getIsOpen">
				<div data-element="summary-wrapper">
					<deal-matrix
						:isSimulatorRemote="true"
					/>
				</div>
				<controls
					:isRemote="true"
				/>
			</template>
		</div>
	</div>
</template>

<script>

	import DealMatrix  from '@/components/diagrams/DealMatrix';
	import Controls    from '@/components/simulator/Controls';

	export default {
		layout: 'remote',
		components: {
			DealMatrix,
			Controls
		},
		computed: {
			getIsOpen () {
				return this.$store.getters['simulatorControls/getRemoteControlWindowIsOpen'];
			},
			getErrorMessage () {
				// TODO: Use the correct message after the control for the simulator is developed
				// Remote controls must be launched from the deal simulator
				return 'The remote control for the simulator is under development and currently unavailable';
			}
		},
		created () {
			// setInterval(this.loop, 10000);
		},
		beforeDestroy () {
			// clearInterval(this.loop);
		},
		methods: {
			// loop () {
			// 	this.$store.commit('simulatorControls/setRemoteControlWindowIsStillOpenAt', {
			// 		date: new Date()
			// 	});
			// }
		}
	};

</script>

<style lang="scss" scoped>
	[data-route] {
		min-height:100vh;
		margin:0;
		padding:0;
		background-color:$c-brand-red-lighter-4;
		[data-element='container'] {
			display:flex;
			flex-direction:column;
			align-items:center;
			width:263px;
			padding:rem(10);
			[data-element='error-text'] {
				width:100%;
				padding:rem(24);
				background-color:$c-white;
				border-radius:8px;
			}
			[data-element='summary-wrapper'] {
				margin:0 0 rem(12) 0;
				padding:rem(24);
				background-color:$c-white;
				border-radius:8px;
				[data-component='deal-matrix'] {}
			}
			[data-component='controls'] {
			}
		}
	}
</style>
